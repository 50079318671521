const conditions = {
  193: {
    name: "Panicked",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  194: {
    name: "Scared",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  713: {
    name: "Frostbite",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  714: {
    name: "First-degree burn",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  715: {
    name: "Wound",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  751: {
    name: "Blast injury",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1003: {
    name: "Monster bite",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1033: {
    name: "Ate without table",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1053: {
    name: "Feeling a little hungry",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1058: {
    name: "Feeling a little unsafe",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1059: {
    name: "Slept on the floor",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1060: {
    name: "Holding it in",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1061: {
    name: "It's so dark on this spaceship",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1062: {
    name: "Ate the meat of a human being",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1063: {
    name: "Wearing spacesuit",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1064: {
    name: "Feeling Adventurous",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 10,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1065: {
    name: "Feeling Meaningful",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 5,
      social: 0,
      mood: 10,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1066: {
    name: "Feeling loved",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1096: {
    name: "Shat pants",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1108: {
    name: "Unconscious",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1109: {
    name: "Starvation",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1112: {
    name: "Low oxygen",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1118: {
    name: "CO2 condition",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1119: {
    name: "Hazardous gas",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1120: {
    name: "Smoke condition",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1121: {
    name: "Low body temperature",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1122: {
    name: "High body temperature",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1123: {
    name: "Ate too much",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1124: {
    name: "Ate monster meat",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1125: {
    name: "Ate spoiled food",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1127: {
    name: "Lonely",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1430: {
    name: "Cocooned",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1550: {
    name: "Did something I love",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 10,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1561: {
    name: "Black eye",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1563: {
    name: "Did something I dislike",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: -10,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1569: {
    name: "Uncomfortable environment",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1570: {
    name: "Feeling comfortable",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1581: {
    name: "Minor discomfort",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1582: {
    name: "Moderate discomfort",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1583: {
    name: "Major discomfort",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1584: {
    name: "Feeling a little unsafe",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1585: {
    name: "Fearful",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1586: {
    name: "Terrified",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1587: {
    name: "Feeling left out",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1588: {
    name: "Feeling isolated and lonely",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1589: {
    name: "Feeling completely alone and unloved",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1590: {
    name: "Low energy",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1591: {
    name: "Feeling fatigued",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1592: {
    name: "Extremely fatigued",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1593: {
    name: "Feeling slightly hungry",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1594: {
    name: "Feeling hungry",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1595: {
    name: "Feeling very hungry",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1596: {
    name: "Some health problems",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1597: {
    name: "Concerning health problems",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1598: {
    name: "Serious health problems",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1600: {
    name: "Having mental breakdown",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1622: {
    name: "Heard a funny joke",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1623: {
    name: "Someone was mean to me",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1624: {
    name: "Got comforted",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1625: {
    name: "Got rejected",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1626: {
    name: "Someone thanked me",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1648: {
    name: "Cryo sleep",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1649: {
    name: "Wall cocoon",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1739: {
    name: "Spacesuit fatigue",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  1957: {
    name: "Wound",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2055: {
    name: "Resting",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2056: {
    name: "Resting in medical bed",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2057: {
    name: "Open wound",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2080: {
    name: "Aliens haunt me in my dreams",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: -8,
      safety: -12,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2081: {
    name: "I was held as a prisoner",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: -8,
      safety: -8,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2083: {
    name: "Stockholm syndrome",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2246: {
    name: "Working comfortably",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2247: {
    name: "Resting comfortably",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2248: {
    name: "Working uncomfortably",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2417: {
    name: "Prisoner recruitment",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2482: {
    name: "Concussion",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2490: {
    name: "Disorientation",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2491: {
    name: "Lost appetite",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2492: {
    name: "Insomnia",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2493: {
    name: "Unable to work",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2494: {
    name: "Psychosis",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2495: {
    name: "Aggressive behavior",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2496: {
    name: "Schizophrenia",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2497: {
    name: "Urge to destroy",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2498: {
    name: "Suicidal",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2499: {
    name: "Attempted suicide",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2500: {
    name: "Poisoned",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2512: {
    name: "Saw a captive crew member",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2664: {
    name: "Nausea",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2667: {
    name: "Protein deficiency",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2668: {
    name: "Fatty acids deficiency",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2669: {
    name: "Craving for carbohydrates",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2670: {
    name: "Vitamin deficiency anemia",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2728: {
    name: "Wound",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2729: {
    name: "Monster bite",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2798: {
    name: "Received an electric shock",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: 0,
      safety: 0,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
  2843: {
    name: "I was held as a slave",
    human: true,
    robot: false,
    monster: false,
    affects: {
      workSpeed: 0,
      social: 0,
      mood: -8,
      safety: -8,
      rest: 0,
      comfort: 0,
      accuracy: 0,
      accidentRate: 0,
      learningRate: 0,
      recoverRate: 0,
      health: 0,
    },
  },
};

export default conditions;
