const skills = {
  1: { name: "piloting" },
  2: { name: "mining" },
  3: { name: "botany" },
  4: { name: "construct" },
  5: { name: "industry" },
  6: { name: "medical" },
  7: { name: "gunner" },
  8: { name: "shielding" },
  9: { name: "operations" },
  10: { name: "weapons" },
  11: { name: null },
  12: { name: "logistics" },
  13: { name: "maintenance" },
  14: { name: "navigation" },
};

export default skills;
