const elements = {
  15: { name: "Root vegetables" },
  16: { name: "Water" },
  // 34: { name: "Power" },
  40: { name: "Ice" },
  // 63: { name: "Oxygen" },
  // 64: { name: "CO2" },
  71: { name: "Bio Matter" },
  // 73: { name: "Heat" },
  // 77: { name: "Smoke" },
  127: { name: "Rubble" },
  157: { name: "Base Metals" },
  158: { name: "Energium" },
  162: { name: "Infrablock" },
  169: { name: "Noble Metals" },
  170: { name: "Carbon" },
  171: { name: "Raw Chemicals" },
  172: { name: "Hyperium" },
  173: { name: "Electronic Component" },
  174: { name: "Energy Rod" },
  175: { name: "Plastics" },
  176: { name: "Chemicals" },
  177: { name: "Fabrics" },
  178: { name: "Hyperfuel" },
  179: { name: "Processed Food" },
  706: { name: "Fruits" },
  707: { name: "Artificial Meat" },
  712: { name: "Space Food" },
  725: { name: "Assault Rifle" },
  728: { name: "SMG" },
  729: { name: "Shotgun" },
  760: { name: "Five-Seven Pistol" },
  930: { name: "Techblock" },
  // 971: { name: "Hazardous Gas" },
  984: { name: "Monster Meat" },
  985: { name: "Human Meat" },
  // 1445: { name: "Building tools" },
  // 1858: { name: "Credits" },
  1759: { name: "Hull Block" },
  1873: { name: "Infra Scrap" },
  1874: { name: "Soft Scrap" },
  1886: { name: "Hull Scrap" },
  1919: { name: "Energy Block" },
  1920: { name: "Superblock" },
  1921: { name: "Soft Block" },
  1922: { name: "Steel Plates" },
  1924: { name: "Optronics Component" },
  1925: { name: "Quantronics Component" },
  1926: { name: "Energy Cell" },
  1932: { name: "Fibers" },
  1946: { name: "Tech Scrap" },
  1947: { name: "Energy Scrap" },
  1954: { name: "Human Corpse" },
  1955: { name: "Monster Corpse" },
  2053: { name: "Medical Supplies" },
  2058: { name: "IV Fluid" },
  // 2452: { name: "Water vapor" },
  2657: { name: "Nuts and Seeds" },
  2475: { name: "Fertilizer" },
  2715: { name: "Explosive Ammunition" },
};

export default elements;
